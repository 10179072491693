defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Components/GameClients/VikingLottoClassicClient', [
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
  'Shared/Framework/Mithril/Scripts/Helpers/Storage',
  'Common/Framework/EventHandling/Scripts/Event',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Classic/Classic',
  'DanskeSpil/Framework/NumberGames/Scripts/Templates/ErrorOverlay',
  'DanskeSpil/Framework/NumberGames/Scripts/Templates/JokerOverlay',
  'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
  'DanskeSpil/Framework/NumberGames/Scripts/Templates/SubscriptionValidationOverlay',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/StatisticsComponentForGameClient',
  'DanskeSpil/Domain/VikingLotto/Scripts/Models/VikingLottoGame',
  'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoDictionary',
  'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoInfo',
  'DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoClassic/DeleteOverlay',
  'DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoClassic/Rows',
  'DanskeSpil/Domain/VikingLotto/Scripts/Templates/PurchaseBar',
  'DanskeSpil/Domain/VikingLotto/Scripts/Templates/Subnavigation',
  'DanskeSpil/Domain/VikingLotto/Scripts/Templates/ShakeOverlay'
], function (Params, Storage, Event, NumberGamesClassic, ErrorOverlay, JokerOverlay, Overlay, SubscriptionValidationOverlay, StatisticsComponentForGameClient, VikingLottoGame, VikingLottoDictionary, VikingLottoInfo, DeleteOverlay, Rows, PurchaseBar, Subnavigation, ShakeOverlay) {

  // Client:
  var GameClient = function (m, settings, property) {

    // Components:
    var root = {
      controller: function () {
        this.settings = settings;
        this.property = property;

        // Game:
        var gameInstanceId = Params.extract('gameInstanceId');

        if (gameInstanceId) {
          var game = VikingLottoGame.get(gameInstanceId);

          if (!game) {
            gameInstanceId = undefined;
          }
        }

        // Variables:
        this.d = VikingLottoDictionary.get;
        this.deleteOverlay = m.prop(new Overlay(DeleteOverlay(this.d)));
        this.gameInstanceId = m.prop(Params.extract('gameInstanceId'));
        this.game = m.prop(game ? game : VikingLottoGame.new({ playType: 'Classic' }));
        this.errorOverlay = m.prop(new Overlay(ErrorOverlay('vikinglotto-classic-game-overlay', {
          header: this.d('GenerateRowsErrorOverlay/Header'),
          body: this.d('GenerateRowsErrorOverlay/Body'),
          dismiss: this.d('GenerateRowsErrorOverlay/Dismiss')
        })));
        this.jokerOverlay = m.prop(new Overlay(JokerOverlay(this.game(), 'vikinglotto-classic-game-overlay', this.d, VikingLottoInfo.jokerData())));
        this.subscriptionValidationOverlay = m.prop(new Overlay(SubscriptionValidationOverlay('vikinglotto-classic-game-overlay subscription-validation-overlay', {
          header: this.d('SubscriptionValidationOverlay/Header'),
          body: this.d('SubscriptionValidationOverlay/Body'),
          dismiss: this.d('SubscriptionValidationOverlay/Dismiss')
        })));
        this.pageReload = m.prop(typeof gameInstanceId !== 'undefined');
        this.property = property;
        this.property('currentRow', 1);
        this.subnavigation = m.prop(settings.quickLinks);
        this.hasDisplayedCreateNewRowHelp = m.prop(Storage.get('viking-hasDisplayedCreateNewRowHelp'));

        // Functions:
        this.purchase = function () {
          var game = this.game();
          var self = this;

          if (game.isGameValid()) {

            // Abort purchase and show subscription validation overlay, if subscription is chosen but not valid
            if (game.numberOfDraws() === 0 && !game.isSubscriptionValid()) {
              this.subscriptionValidationOverlay().show();
              return;
            }

            this.jokerOverlay().show(function () {
              game.prepareConfirm().then(function (confirmUrl) {
                if (game.numberOfDraws() === 0) {
                  game.sendToSubscription();
                } else {
                  location.href = confirmUrl;
                }
              }, function () {
                self.errorOverlay().show();
              });
            });

          }
        }.bind(this);

        this.dontDisplayCreateNewRowHelpAgain = function () {
          this.hasDisplayedCreateNewRowHelp = m.prop(true);
          Storage.set('viking-hasDisplayedCreateNewRowHelp', true);
        }.bind(this);

        // Replace state:
        if (!gameInstanceId || gameInstanceId !== this.game().id()) {
          Params.set('gameInstanceId=' + this.game().id());
        }

        // Tracking - Push productDetails event to dataLayer
        this.game().trackingProductDetails();

        Event.subscribe('vikinglotto:classic:createNewRow', () => this.property('callToCreateNewRow', true));
      },

      view: function (controller) {
        if (settings.useNewNumbersPicker) {
          return (<NumberGamesClassic
            controller={controller}
            gameModel={VikingLottoGame}
            gameName={'vikinglotto'}
            rowsOptions={VikingLottoInfo.getClassicRowOptions()}
            defaultRowCount={VikingLottoInfo.getClassicRowDefaultRowCount()}
          />);
        }

        var version = controller.game().version();

        return m('div', { class: 'vikinglotto-classic-game' }, [
          ShakeOverlay(controller),
          !settings.subscriptionsMode ? Subnavigation(controller) : null,
          StatisticsComponentForGameClient(controller, 'Vikinglotto'),
          m('div', { class: 'vikinglotto-section vikinglotto-classic-game-section purchase-bar-container' }, [
            m('div', { class: 'vikinglotto-content-wrapper vikinglotto-content-wrapper--version-' + version }, [
              Rows(controller),
              !settings.subscriptionsMode && !controller.property('isMultiClient') ? PurchaseBar(controller) : null
            ])
          ])
        ]);
      }
    };

    return root;
  };

  // Public functions:
  return GameClient;

});
