defineDs('DanskeSpil/Domain/VikingLotto/Scripts/Components/GameClients/VikingLottoSystemClient',
  [
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/System',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/ErrorOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/JokerOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/StatisticsComponentForGameClient',
    'DanskeSpil/Domain/VikingLotto/Scripts/Models/VikingLottoGame',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoDictionary',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoInfo',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoSystem/TypeSwitch',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoSystem/OptionSelector',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoSystem/Row',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoSystem/ProfitGuaranteeTab',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoSystem/InfoTab',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/VikingLottoSystem/KeyTab',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/PurchaseBar',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/Subnavigation',
    'DanskeSpil/Domain/VikingLotto/Scripts/Templates/ShakeOverlay',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/PrintWrapper'
  ],
  function (Params, NumberGamesSystem, ErrorOverlay, JokerOverlay, Overlay, StatisticsComponentForGameClient, VikingLottoGame, VikingLottoDictionary, VikingLottoInfo, TypeSwitch, OptionSelector, Row, ProfitGuaranteeTab, InfoTab, KeyTab, PurchaseBar, Subnavigation, ShakeOverlay, PrintWrapper) {

    // Client:
    var GameClient = function (m, settings, property) {

      // Components:
      var root = {
        controller: function () {
          this.settings = settings;
          this.property = property;

          // Variables:
          this.chosenTab = m.prop('game');
          this.d = VikingLottoDictionary.get;
          this.gameInstanceId = m.prop(Params.extract('gameInstanceId'));
          this.game = m.prop(VikingLottoGame.setupGame({ gameInstanceId: this.gameInstanceId(), playType: 'System' }));
          this.errorOverlay = m.prop(new Overlay(ErrorOverlay('vikinglotto-system-game-overlay', { header: this.d('GenerateRowsErrorOverlay/Header'), body: this.d('GenerateRowsErrorOverlay/Body'), dismiss: this.d('GenerateRowsErrorOverlay/Dismiss') })));
          this.jokerOverlay = m.prop(new Overlay(JokerOverlay(this.game(), 'vikinglotto-system-game-overlay', this.d, VikingLottoInfo.jokerData())));
          this.pageReload = m.prop(typeof gameInstanceId !== 'undefined');
          this.print = m.prop(false);
          this.property = property;
          this.property('currentRow', 1);
          this.subnavigation = m.prop(settings.quickLinks);

          // Functions:
          this.changeTab = function (tab) {
            var self = this;

            return function () {
              self.chosenTab(tab);
            };
          }.bind(this);

          this.purchase = function () {
            var game = this.game();
            var self = this;

            if (game.isGameValid()) {
              this.jokerOverlay().show(function () {
                game.prepareConfirm().then(function (confirmUrl) {
                  if (game.numberOfDraws() === 0) {
                    game.sendToSubscription();
                  } else {
                    location.href = confirmUrl;
                  }
                }, function () {
                  self.errorOverlay().show();
                });
              });
            }
          }.bind(this);

          // Replace state:
          if (!this.gameInstanceId() != this.game().id()) {
            Params.set('gameInstanceId=' + this.game().id());
          }

          this.game().trackingProductDetails();

        },

        view: function (controller) {
          var version = controller.game().version();
          if (settings.useNewNumbersPicker) {
            return (<NumberGamesSystem
              controller={controller}
              gameModel={VikingLottoGame}
              gameName={'vikinglotto'}
              systemsTypes={['M', 'R', 'C']}
            />);
          }

          return m('div', [
            PrintWrapper(controller, 'vikinglotto', controller.chosenTab()),
            m('div', { class: 'vikinglotto-system-game' }, [
              ShakeOverlay(controller),
              !settings.subscriptionsMode ? Subnavigation(controller) : null,
              TypeSwitch(controller),
              OptionSelector(controller),
              StatisticsComponentForGameClient(controller, 'Vikinglotto'),
              m('div', { class: 'vikinglotto-section vikinglotto-system-game-section purchase-bar-container' }, [
                m('div', { class: 'vikinglotto-content-wrapper vikinglotto-content-wrapper--version-' + version }, [
                  m('div', { class: 'vikinglotto-game-tabs-wrapper' }, [
                    m('div', { class: 'tabs-navigation' }, [
                      m('div', { class: 'tab-navigation-item' + (controller.chosenTab() === 'game' ? ' active' : ''), onclick: controller.changeTab('game') }, [
                        m('svg', { class: 'tab-navigation-icon' }, [
                          m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesTabsNavigationIcons.svg#game-menu-icon' })
                        ])
                      ]),
                      m('div', { class: 'tab-navigation-item' + (controller.chosenTab() === 'info' ? ' active' : ''), onclick: controller.changeTab('info') }, [
                        m('svg', { class: 'tab-navigation-icon' }, [
                          m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesTabsNavigationIcons.svg#info-icon' })
                        ])
                      ]),
                      m('div', { class: 'tab-navigation-item' + (controller.chosenTab() === 'key' ? ' active' : ''), onclick: controller.changeTab('key') }, [
                        m('svg', { class: 'tab-navigation-icon' }, [
                          m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesTabsNavigationIcons.svg#key-icon' })
                        ])
                      ]),
                      m('div', { class: 'tab-navigation-item' + (controller.chosenTab() === 'profit-guarantee' ? ' active' : ''), onclick: controller.changeTab('profit-guarantee') }, [
                        m('svg', { class: 'tab-navigation-icon' }, [
                          m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesTabsNavigationIcons.svg#stamp-icon' })
                        ])
                      ])
                    ]),
                    m('div', { class: 'tabs-container' }, [

                      // Game tab:
                      controller.chosenTab() === 'game' ? m('div', { class: 'tab-container' }, [
                        m('div', { class: 'game-container' }, [
                          Row(controller)
                        ])
                      ]) : null,

                      // Info tab:
                      controller.chosenTab() === 'info' ? m('div', { class: 'tab-container' }, [
                        InfoTab(controller)
                      ]) : null,

                      // Key tab:
                      controller.chosenTab() === 'key' ? m('div', { class: 'tab-container' }, [
                        KeyTab(controller)
                      ]) : null,

                      // profit guarantee tab:
                      controller.chosenTab() === 'profit-guarantee' ? m('div', { class: 'tab-container' }, [
                        ProfitGuaranteeTab(controller)
                      ]) : null

                    ])
                  ]),
                  !settings.subscriptionsMode && !controller.property('isMultiClient') ? PurchaseBar(controller) : null
                ])
              ])
            ])
          ]);
        }
      };

      return root;
    };

    // Public functions:
    return GameClient;

  });
